/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 767px) {

	.wrapper {width: auto; margin: 0 20px;}   
	.btn {
		height: 40px; line-height: 40px; font-size: 14px; 
		&.btn-catalog {
			width: 100%; margin: 0 0 15px 0; text-align: left; 
			span {
				&:before { 
					top:-2px;
				}
			}
		}
	}
	.btn-play {
		width: 60px; height: 60px;
		&.right {
			margin-top: -65px; margin-left: -65px; 
		}
	}
	.selectbox {
		select {padding-left: 0 14px;}
	}

	h3 {font-size: 18px;} 

	header { 
		background: #fff; 		
		.wrapper {height: 70px; margin-top: 0; border: 0;}    
		.burger-menu {
			top: 24px; width: 24px; height: 20px; left: 0; z-index: 10;   
			span {
				height: 4px; 
				&:nth-child(2) {top: 8px;}   
				&:nth-child(3) {top: 16px;}   
			}
		}     
		/*.logo {width: 120px; height: 42px; margin-left: 0;}     */
		.logo {width: 110px; height: auto; margin-left: 0;}     
		.right {
			.box-other { 
				padding-top: 22px; padding-right: 0; 
				&:after { 
					width:300px;   
					@include borderRadius(0); 
				}
				.join {display: none;} 
				.box-search {margin: 0;}  
			} 
		} 
		.view-search {
			width: 280px;  
		}
	}
	.box-lang {
		.select select {font-size: 13px;}  
	}
	.join a {font-size: 14px;} 
	.homepage {   
		.btn {height: 34px; line-height: 34px;} 
		.wrapper {width: auto;}   
		header, footer {
			.wrapper {width: auto;}          
		}
	} 

	.contentpage {
		header {
			.wrapper {width: auto;} 
		}
		.middle {padding: 70px 0 0 0;}
	}
	.contentpage2 {
		.wrapper {width: auto;} 
		header {
			.wrapper {width: auto;} 
			&.full {
				.wrapper {height: 70px}
			}
		}
		.middle {padding: 70px 0 0 0;}
	}

	.banner-page {
		figure {height: 170px;}  
	}
	.breadcrumb {  
		ul {
			li {
				font-size: 13px; padding: 0 20px 0 20px; display: none;
				&:first-child{display:inline-block;}     
				&:last-child{display:inline-block;}    
				&:after {top:14px;}  
				a {padding: 12px 0;}  
			}
		} 
	} 

	.nav-sub {
		padding-bottom: 18px;  
		ul {	
			width: 115px; padding-top:40px; position: relative; 
			&:after {
				position:absolute; width: 100%; height: auto; content: "Category"; top: 2px; left: 0; 
				font-weight: bold; font-size: 13px; 
			}
			&:before {
				position:absolute; width: 100%; height: 40px; top: 0; left: 0; content: ""; 
				background:transparent; z-index: 3;  
			}   		
			li {
				display: block; padding: 0 0;  
				a {
					display: none; padding: 5px 0;  
					&.active {
						display: block; position: absolute; width: 100%; left: 0; top: 19px;
						padding: 0;  
						&:after {
							width:20px; height: 18px;  top: 0; left: 100%; margin-left: -16px; 
							background:url('../images/material/ico-arrow-h.png') no-repeat center center;
							@include transform(rotate(90deg) translate(0)); 							
						}
					}
					&:hover {
						&:after {
							display:none; 
						}
					}
				}
			}
		}
		&.expand {
			ul {
				li {
					a {
						display: block; 
						&.active {}  
					}
				}
			}
		}
	}

	.popup-menu {
		padding: 10px; 
		.inner {
			padding: 0 20px;  
		}
		.nav-mob {
			padding-top: 70px; padding-bottom: 100px; 
			ul {
				li {
					a {
						padding: 12px 0; font-size: 16px; 
					}
				}
			}
		}
		.close-pop {
			top: 25px; right: 15px; 
		} 
		.bottom-pop {
			padding: 15px 20px 20px 20px;  
			&:before {width:20px;}
			&:after {width: 20px;} 
		}
	}
	.middle {
		padding: 70px 0 0 0; 
		&.mid-home {background: #97c3da;}  
		&.bg-retail-detail {}
	}
	.boxlist-md {
		height: auto; background: none; 
		.box {
			width: 48%; margin-right:4%; margin-bottom: 4%; height: 200px;     
			&:nth-child(2n+2) {margin-right: 0;}  
			&:last-child {}
			figure {
				width: 54%; margin: 8px 0 0 23%; 
			}
			.link-abs {width: 54%; margin: 10px 0 0 23%;}
			&:hover { 
	            figure {
	                img {
	                    @include transform(scale(1)); 
	                }
	            }
	        }
		} 		
	} 
	.boxlist-lg {   
		.box {
			width: 70%; margin: 0 auto 5% auto; float: none; height: 240px;  
			figure { 
				width: 78%; 
			    margin: 0 0 0 10%;
			    padding: 25px 0 0 0;
			    height: 75%;	
			}  
			&:nth-child(2n+2) { 
				width: 100%;
				figure {  
					width: 57%; 
				    margin: 0 0 0 21%;
				    padding: 28px 0 0 0;
				    height: 75%;	
				}    
				.link-abs {
					width: 57%; 
				    margin: 0 0 0 21%;
				    padding: 28px 0 0 0;
				    height: 75%;
				}
			} 
			&:last-child {margin-right: auto;}
			&:hover { 
	            figure {
	                img {
	                    @include transform(scale(1)); 
	                }
	            }
	        }  
	        .link-abs {
	        	width: 78%; 
			    margin: 0 0 0 10%;
			    padding: 25px 0 0 0;
			    height: 75%;
	        }
		} 		
	} 
	.boxlist-sm { 
		height: auto;
		.box {
			width: 48%; margin-right:4%; margin-bottom: 7%; height: 200px;    
			&:nth-child(2n+2) {margin-right: 0;}  
			&:last-child {}
			figure {
				width: 68%; margin: 10px 0 0 17%; height: 88%; 
			} 
			.link-abs {
				width: 68%; margin: 10px 0 0 17%; height: 88%;
			}
			&:hover { 
	            figure {
	                img {
	                    @include transform(scale(1)); 
	                }
	            }
	        }
		} 		
	} 
	/*.select2-container--open .select2-dropdown.select2-default {margin-left: 20px;}
	.select2-container--open .select2-dropdown.select2-std {
		margin-left: 20px;
	} */ 

	.section-home { 
		&.section-1 {background: none;} 
		&.section-2 {background: none;} 
		&.section-3 {
			background: none;
			&:after {display:none;} 
			&.active { 
				padding-bottom: 0;  
				&:after {display:none;}  
			}
		}
		.row-box {
			&.styled-1 {
				height: 520px;  
				background: url('../images/material/mob-bg-section-1.png') no-repeat center bottom; 
				.wrapper {
					width: 300px; margin: auto; padding: 40px 0 25px 0;					
				}    
			}
			&.styled-2 { 
				height: 942px; 
				background: url('../images/material/mob-bg-section-2.png') no-repeat center bottom;  
				.wrapper {width: 300px; margin: auto; padding: 70px 0 50px 0;}      
			} 
			&.styled-3 {
				height: 515px; 
				background: url('../images/material/mob-bg-section-3.png') no-repeat center bottom; 
				.wrapper {width: 300px; margin: auto; padding: 40px 0 25px 0;}     
			}
		}
		.row-text {display: none;} 
		&.active {
			.row-text {display: none;}    
		}
	}

	.row-main {
		padding: 30px 0 50px 0; 	
		.article {
			ul {
				margin-bottom: 20px;
			}
		}	
		&.rm-2 {
			.wrapper {width: auto;} 
		} 
		&.form {
			padding: 0 0 0 0; 
			.wrapper {width: auto; margin: 0;}  
		}
		&.rm-event {
			background: #184451; 
			.main-content {
				@include borderRadius(6px); 
				&:before,
				&:after {display:none;} 
			}
		}
		.side {
			width: 100%; padding: 100px 20px 20px 20px;  
			.close-side {right: 10px;top: 95px;}     
		}    
		.main-content { 
			h1 {
				font-size: 18px;
				&.md {font-size: 18px;}     
			} 
			a.btn-post {
				margin-top: 0; font-size: 14px; width: 110px; overflow: hidden;
				span {
					padding: 0; 
					&:before{display:none;} 
				}
			} 
			.ribbon.relative.mg-left {float: left;} 
			.top-mid {
				h1 {margin: 0;} 
				.left {margin: 0 0 15px 0;}  
			}
			.main-filter {				 
				padding: 0 0 10px 0; margin-bottom: 25px;  
				h1 {margin-top: 0; font-size: 20px;} 
				form {width: 100%;}  
				.left {width: 100%; margin-bottom: 15px;}  
				.right {
					position: relative; width: 100%; 
					.col {
						width: 100%; margin: 0 0 0px 0; 
						&.selectbox {margin-bottom: 15px;} 
						&.fm-search {margin-bottom: 15px;}  
					} 
				}
				.burger-filter {
					position: absolute; right: 0; top: -13px;     
				}
				&.no-border {margin-bottom: 10px;}
			}
		}
		.notfound {
			&.article {
				width: auto;
				.c4-title {padding: 0 30px;}   
				.subtitle {font-size: 30px;}  
			} 
		}

	}

	.product-list { 
		.list-item { 
			width: 50%; 
			figure {
				padding: 0 10px; height: 130px; margin-bottom: 15px;   
				img {}  
			}
			.title {font-size: 15px; line-height: 20px; /*max-height: 44px;*/}      
		}
		&.other-list {
			.list-item {
				&:nth-child(3) {display: none;}   
			}
		}
	}
	.retail-listing {
		padding: 0; 
		.list-item {
			width: 100%; margin: 0 0 25px 0; 
			.col {
				&.figure {height: 115px;}  
				&.text {
					padding: 0 0 0 15px;  
					h4 {font-size: 15px;}
					.shortdes {font-size: 14px; line-height: 20px;}  
				}
			}
		}   
	} 
	.space-v {height: 40px;}  
	.paging {
		ul {
			li {
				padding: 0 1px; font-size: 14px;  
				a {padding: 4px 7px;}  
			} 
		}
	}

	.box-large {		
		.col {
			width: 100%;
			&.figure {   
				position: relative; z-index: 5;
			    iframe {
			    	width: 100% !important; height: 280px !important;} 
				} 
			&.text {
				padding: 40px 0 0 0; width: 100%;   
			}
		}
		&.md {
			.col {
				&.figure {
					width: 100%;
					.productSlide {margin-bottom: 20px;}  
					.thumbSlide {
						margin: 0 auto; width: 245px; 
						.slide {width: 65px; height: 65px;}    
					}
				} 
				&.text {width: 100%; padding: 20px 0 0 0;}  
			}
		}
		&.event {
			padding: 20px 20px 30px 20px;  
			&:before,
			&:after {display:none;} 
			.col {
				&.figure {width: 100%; height: auto;}  
				&.text {
					padding: 30px 0 0 0; 
				}
			}
			&.event-2 { 
				.figure {height: 170px;} 
			}
			.row {
				&.full {
					padding: 0;
					.col {
						&.figure {
							&:before, 
							&:after {
								width:80px;
								background-size:50%; 
							}
							&:before {left: -50px;}
							&:after {right: -50px;}
						}
					}
				}
			}
			&.ltr {
				.row {
					.col {
						&.text {padding: 30px 0 0 0;} 
					}
					&:nth-child(even) {
						.col {
							&.text {padding: 30px 0 0 0;}
						}
					}
					&:last-child {margin-bottom: 0;} 
				}
			}
		}	

		.retailSlider { 
			/*.slide {
				height: 286px;   
			}*/
			/*iframe {  
	            position: relative; top: 0; left: 0;
	        }*/
		}
	}
	.row-event {
		padding: 30px 20px;
		.part-bottom {
			padding: 20px; margin-bottom: 40px;   
			.cl {
				&.left {width: 100%; padding: 0 0 15px 0; text-align: center;}    
				&.right {
					width: 100%; padding: 0; text-align: center; 
					a {
						display: inline-block; margin-bottom: 15px;
					}
				} 
			}
			&.v2 {padding: 20px;} 
		}
		&.ev-pets {
			padding: 30px 20px; 
			.box-large {
				&.event {
					&.event-2 {
						.row {padding-bottom: 20px; margin-bottom: 35px;}
					}
				}
			}
		}
	}
	.box-address {
		padding: 20px;
		.row {
			.cl {
				display: block; 
				&:nth-child(1) {width: 100%; padding-bottom: 0;} 
				&:nth-child(2) {width: 100%; padding-bottom: 10px;}  
			}
		}
		&.v2 {
			padding-left: 25px; 
			.row {
				width: 100%; 
				.cl {
					display: inline-block; margin-left: -4px; padding: 0 0 5px 0;  
					&:nth-child(1) {width: 50%; padding-bottom: 5px;} 
					&:nth-child(2) {width: 50%; padding-bottom: 5px;}  
				}
			}
		}
	}
	.box-cate-item {
		.item {
			width: 55%; padding-right: 5px;  
			&:nth-child(1) {width: 55%;}   
			&:nth-child(2n+2) {width: 45%;} 
			&:nth-child(3) {width: 55%;} 
			&:nth-child(4) {width: 45%;} 
			&:nth-child(5) {width: 55%;} 
			.cl {
				&.ico {
					img {}
				}				
			}
		}
	}
	.row-certificate {
		margin-bottom: 20px;  
		.col {
			margin-bottom: 25px;   
			&:first-child {width:100%;} 
			&:last-child {width: 100%; padding-left: 0; margin-bottom: 0;}     
			h4 {font-size: 16px;} 
		}
	}
	.row-description {
		padding:10px 20px; 
	}
	.col-accordion {
		.acclist {
			.acc-title {font-size: 15px;} 
		}
	}
	.frame-listing {
		.list-item {
			width: 100%; margin: 0 0 30px 0;
			figure {height: 185px;} 
			&.hv_vote {
				.text {
					.short-descrip {padding-top: 0;}
					.flag {
						width: auto;
						.ribbon {
							display: inline-block; position: relative; top: 0; float: right;
							&:last-child {margin-right: 20px !important; margin-top: 0;}
							&:first-child {margin-right: 0px !important;}
						}
					}
				}
			}
		}
		&.col-2 {
			.list-item {
				width: 100%; margin: 0 0 30px 0;
			}
		}
		&.full {
			padding:20px 20px 30px 20px; margin-bottom: 20px; 
			.list-item {
				figure {width: 100%;}
				.text {
					width: 100%; padding: 20px 0 0 0;
					.title {font-size: 20px;}
				} 
			}
		}
		&.other {
			padding: 20px;   
			.list-item {
				width: 100%; margin: 0 0 30px 0;
 			}
		}
	}
	.other-product { 
		margin: 40px 0 0 0;  
	}
	.text-listing {
		padding: 20px;  
		.row {
			ul {
				li {width: 100%;}
			}
		}
	}
	.std {
		label {line-height: 25px; padding-bottom: 5px;}  
		.col {
			width: 100%; display: block;  
		}
		.left {
			&.fish {margin-left: 0;} 
		}
		.fm-search {
			input[type="text"] {
				min-width: 100%; width: 100%; height: 100%;
				@include boxSizing(border-box);
			}  
		}
		.select2-container {padding: 0;} 		
	}
	.part-vote {
		padding: 20px; 
		.left {width: 100%; padding: 0 0 20px 0;} 
		.right {
			width: 100%; text-align: center; 
		}
	}

	.row-about {
		.col {
			width: 100%;
			&.text {
				width: 100%; padding: 30px 0 0 0;  
				p {line-height: 24px;} 
			}
		} 
	}

	.sitemap { 
		.row {
			.col {
				width: 46%; margin-right: 7%;   
				&:nth-child(2n+2) {margin-right: 0;}
			} 
		}
	}

	.form-container {
		padding: 20px 20px 30px 20px; 
		.inner {
			padding: 30px 20px; 
		}
	}
	.form-std {
		input {height: 40px; line-height: 40px;} 
		.selectbox {height: 40px; line-height: 40px;} 
		.input_file {
			height: 40px; line-height: 40px; padding-right: 80px;
			input {padding-right: 80px; padding-left: 10px;}  
			&:after {
				height:40px; line-height: 40px; width: 75px; font-size: 14px;     
			}
			span {
				&.filled {
					&:after {width:90px;}  
				}
			}
		}
		textarea {
			padding: 14px;
		}
		.field-group {
			&.group-btn {
				.left {
					width: 100%; 
				}
				.right {width: 100%; text-align: center;}
				.btn {width: 80%; margin: auto;} 
			}
		} 
		.select2-container {padding: 0;}  
	}

	.g-recaptcha > div {width: 200px !important; margin: 0 auto;} 
	.g-recaptcha > div > div { 
        width: 100% !important;
        height: 85px;
        transform:scale(0.7); 
        webkit-transform:scale(0.7);
        text-align: center;
        position: relative;
        margin-left: -35px;  
    } 
    .g-recaptcha > div > div iframe {}  

	.select2-container { 
		width: 100% !important; margin-left: 0; padding: 0 0;  
		@include boxSizing(border-box);   
	}
	.select2-dropdown.largedrop {   
		width: 100% !important;    
		margin-left: -20px !important; 
		border-left: 18px solid #fff; 
		border-right: 18px solid #fff;
		@include boxSizing(border-box); 
	} 

	#boxPop {
		min-width: 90%; max-width: 90%; 
		.close_pop {
			top: -40px; right: 0;
		}
	}

	.row-cms {
		font-size: 13px; 
		padding: 30px 0 10px 0;
	}


	footer {
		border-top: 3px solid #f26722;  padding: 30px 0 40px 0;  
		.foot-top {
			text-align: center; 
			.telp {
				float: none; margin:0 auto 10px auto; padding-top: 1px; display:inline-block; width: auto;
				position: relative; height: 32px; 
				@include boxSizing(border-box); 
			} 
			.nav-foot {
				float: none; display: block; text-align: center; padding:0 0; width: 280px; 
				margin: auto; 
				ul {
					li {
						a {
							padding: 0 19px;   
						} 
					}
				}
			}
		}
		.foot-bottom {  
			.copyright {
				float: none; display: block; margin:0 auto 20px auto; text-align: center; 
				line-height: 24px; 
				span {
					display: block; 
				}
			}
			.box-social {
				float: none; display: block; margin: auto; text-align: center;
			}
		}
	}
	.float_ico {
		right: -5px; bottom: 15px;
		img {width: 100px; }
	}

	@keyframes bounching {
	    0% { bottom: 318px; }
	    25% { bottom: 322px; }
	    50% { bottom: 318px;}
	    75% { bottom: 322px; }
	    100% { bottom: 318px; }
	}

} 


@media all and (max-width: 479px) {
	.box-large {
		.link-abs {display: none;} 
	}

}
@media all and (max-width: 400px) {
	.frame-listing {
		.list-item.hv_vote {
			.text {
				.flag {
					width: 130px;
					.ribbon {
						margin-right: 0 !important;
						&:last-child{margin-top: 8px;margin-right: 0 !important;}
						&:first-child{margin-top: 0px;margin-right: 0 !important;}
					}
				}
			}
			&.two-v {
				.short-descrip{padding-top: 24px;}
			}
		}
	}
 }
@media all and (max-width: 374px) { 
	.boxlist-md {
		height: auto;
		.box {
			height: 170px;   
			figure {
				width: 54%;
			}
		} 
	} 
	.boxlist-lg {
		height: auto;
		.box {
			height: 230px;   
			figure {
				padding: 28px 0 0 0; 
    			height: 76%;				
			}
			&:nth-child(2n+2) {
				figure {
					padding: 33px 0 0 0; 
    				height: 71%;
				} 
			}
		} 
	} 
	.boxlist-sm {
		height: auto;
		.box {
			height: 160px;   
			figure { 
				
			}
		} 
	} 
	.section-home {
		.row-box {
			&.styled-1 { 
				height: 450px; 
				.wrapper {width: 280px;}   
			}
			&.styled-2 {
				height: 880px;
				.wrapper {width: 280px; padding-top: 50px;}   
			}
			&.styled-3 {
				height: 450px;  
				.wrapper {width: 280px;}
			}
		}
	}
	.middle {		
		&.bg-retail-detail {}
	}

}
